exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-azure-databricks-and-grafana-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/azure-databricks-and-grafana/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-azure-databricks-and-grafana-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-bitcoin-node-in-a-orange-pi-5-with-umbrel-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/bitcoin-node-in-a-orange-pi-5-with-umbrel/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-bitcoin-node-in-a-orange-pi-5-with-umbrel-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-checking-and-fixing-security-issues-on-orange-pi-5-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/checking-and-fixing-security-issues-on-orange-pi-5/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-checking-and-fixing-security-issues-on-orange-pi-5-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-configuring-keda-for-autoscaling-based-on-rabbitmq-queue-messages-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/configuring-keda-for-autoscaling-based-on-rabbitmq-queue-messages/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-configuring-keda-for-autoscaling-based-on-rabbitmq-queue-messages-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-criando-um-servico-no-ubuntu-para-rodar-uma-imagem-docker-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/criando-um-servico-no-ubuntu-para-rodar-uma-imagem-docker/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-criando-um-servico-no-ubuntu-para-rodar-uma-imagem-docker-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-how-to-host-your-gatsby-website-on-the-onion-network-to-be-accessible-via-tor-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/how-to-host-your-gatsby-website-on-the-onion-network-to-be-accessible-via-tor/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-how-to-host-your-gatsby-website-on-the-onion-network-to-be-accessible-via-tor-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-how-to-modify-the-docker-service-initialization-on-ubuntu-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/how-to-modify-the-docker-service-initialization-on-ubuntu/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-how-to-modify-the-docker-service-initialization-on-ubuntu-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-how-to-solo-mine-bitcoin-with-a-regular-pc-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/how-to-solo-mine-bitcoin-with-a-regular-pc/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-how-to-solo-mine-bitcoin-with-a-regular-pc-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-how-to-use-gatsbyimagedata-in-mdx-body-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/how-to-use-gatsbyimagedata-in-mdx-body/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-how-to-use-gatsbyimagedata-in-mdx-body-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-my-bitcoin-node-with-tor-network-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/my-bitcoin-node-with-tor-network/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-my-bitcoin-node-with-tor-network-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-remote-interpreter-a-new-word-for-develops-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/remote-interpreter-a-new-word-for-develops/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-remote-interpreter-a-new-word-for-develops-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-training-and-deploying-an-unsupervised-learning-model-with-k-means-and-flask-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/training-and-deploying-an-unsupervised-learning-model-with-k-means-and-flask/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-training-and-deploying-an-unsupervised-learning-model-with-k-means-and-flask-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-why-i-create-my-own-blog-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/why-i-create-my-own-blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-why-i-create-my-own-blog-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

